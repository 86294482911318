<template>
  <div>
    <div class="text-header mb-10">About You</div>
    <v-text-field
      label="First name"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      dense
      v-model="firstName"
    ></v-text-field>

    <v-text-field
      label="Email"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      dense
      v-model="email"
      :rules="emailRules"
      required
    ></v-text-field>
    <v-menu
      ref="birthDateMenu"
      v-model="birthDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="birthDate"
          label="Birthday date"
          readonly
          v-bind="attrs"
          v-on="on"
          rounded
          filled
          color="grey"
          class="py-0 my-0"
          dense
          required
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="birthDate"
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
        min="1950-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>

    <v-select
      label="How did you hear about us?"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      dense
      :items="howDidYouHearAboutUsItems"
      v-model="howDidYouHearAboutUs"
    ></v-select>
    <div class="stay-updated-text">
      Stay up to date with energy saving tips, product news and project updates?
    </div>

    <v-checkbox
      v-model="allowEmailMarkeing"
      :label="`Yep, sign me right up`"
    ></v-checkbox>
    <v-btn
      class="sign-up-button my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      :disabled="!isFormValid || isLoading"
      :loading="isLoading"
      @click="saveData"
      >SAVE PROGRESS</v-btn
    >
  </div>
</template>

<script>
import AuthMixin from "@/mixins/AuthMixin.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "aboutyou",
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Save your progress");

    if (this.getCurrentUser.displayName) {
      this.firstName = this.getCurrentUser.displayName;
    }
    if (this.getCurrentUser.email) {
      this.email = this.getCurrentUser.email;
    }
  },

  mixins: [AuthMixin],
  data() {
    return {
      howDidYouHearAboutUs: null,
      firstName: null,

      activePicker: null,
      birthDate: null,
      birthDateMenu: false,

      email: null,
      howDidYouHearAboutUsItems: [
        "Word of mouth",
        "Social media (Instagram, Tik Tok etc.)",
        "TV",
        "Rewards platform",
        "My employer",
        "Other",
      ],
      allowEmailMarkeing: false,
      isLoading: false,
    };
  },
  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getCarbonEmissions"]),
    isFormValid() {
      return (
        this.birthDate &&
        this.firstName &&
        this.email &&
        this.validateEmail(this.email) &&
        this.howDidYouHearAboutUs
      );
    },
    emailRules() {
      return [
        (v) => !!v || "Email is required",
        (v) => !!this.validateEmail(v) || "A valid Email is required ",
      ];
    },
  },

  methods: {
    ...mapActions(["updateUserDocument"]),
    ...mapMutations(["setCurrentAppBarTitle"]),
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async saveData() {
      this.isLoading = true;
      try {
        await this.updateUserDocument({
          firstName: this.firstName,
          email: this.email,
          birthDate: this.birthDate,
          howDidYouHearAboutUs: this.howDidYouHearAboutUs,
          allowEmailMarkeing: this.allowEmailMarkeing,
          carbonEmissions: this.getCarbonEmissions,
        });

        this.$router.push({ name: "ChoosePaymentMethod" });
      } catch (error) {
        console.log(error);
        this.$alertsService.fireGeneralError(error);
      } finally {
        this.isLoading = false;
      }
    },
    save(date) {
      this.$refs.birthDateMenu.save(date);
    },
  },
};
</script>

<style scoped>
.stay-updated-text {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
}
</style>